<template>
  <div class="bg">
    <a-card :bordered="false">
      <a-row :gutter="24">
        <a-avatar :src="avatar"></a-avatar>
        <span style="margin-left: 24px;">{{ getTimeState() }}，{{ nickname }}</span>
      </a-row>
    </a-card>
    <a-card :bordered="false" style="margin-top: 24px;">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="24">
            <a-col :md="8" :sm="24" v-if="!isEnterpriseOrStaff">
              <a-form-item label="企业名称">
                <a-select placeholder="请选择" v-model="queryParam.enterpriseId" style="width: 100%" allow-clear>
                  <a-select-option v-for="(d, index) in enterpriseList" :key="index" :value="d.key">{{ d.value }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="装置名称">
                <a-input v-model="queryParam.deviceName" placeholder="请输入" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-button type="primary" @click="handleQuery">
                <a-icon type="search" />
                查询
              </a-button>
              <a-button style="margin-left: 8px" @click="resetQuery">
                <a-icon type="redo" />
                重置
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </a-card>
    <a-card title="数据统计" :bordered="false" style="margin-top: 24px;">
      <a-row type="flex" justify="space-between">
        <a-col :span="4">
          <div class="statistic-card">
            <a-statistic
              title="待维修数量"
              :value="number1"
              value-style="font-size: 36px; font-weight: bold; color: #2A304D;"
            >
            </a-statistic>
          </div>
        </a-col>
        <a-col :span="4">
          <div class="statistic-card">
            <a-statistic
              title="延迟维修数量"
              :value="number2"
              value-style="font-size: 36px; font-weight: bold; color: #2A304D;"
            >
            </a-statistic>
          </div>
        </a-col>
        <a-col :span="4">
          <div class="statistic-card">
            <a-statistic
              title="已维修数量"
              :value="number3"
              value-style="font-size: 36px; font-weight: bold; color: #2A304D;"
            >
            </a-statistic>
          </div>
        </a-col>
        <a-col :span="4">
          <div class="statistic-card">
            <a-statistic
              title="检测数量"
              :value="number4"
              value-style="font-size: 36px; font-weight: bold; color: #2A304D;"
            >
            </a-statistic>
          </div>
        </a-col>
        <a-col :span="4">
          <div class="statistic-card">
            <a-statistic
              title="泄漏数量"
              :value="number5"
              value-style="font-size: 36px; font-weight: bold; color: #2A304D;"
            >
            </a-statistic>
          </div>
        </a-col>
      </a-row>

      <a-row type="flex" justify="space-between" style="margin-top: 24px;">
        <a-col :span="4">
          <div class="statistic-card">
            <a-statistic
              title="排放量（千克/年）"
              :value="number6"
              value-style="font-size: 36px; font-weight: bold; color: #2A304D;"
            >
            </a-statistic>
          </div>
        </a-col>
        <a-col :span="4">
          <div class="statistic-card">
            <a-statistic
              title="修复后排放量（千克/年）"
              :value="number7"
              value-style="font-size: 36px; font-weight: bold; color: #2A304D;"
            >
            </a-statistic>
          </div>
        </a-col>
        <a-col :span="4">
          <div class="statistic-card">
            <a-statistic
              title="减排量（千克/年）"
              :value="number8"
              value-style="font-size: 36px; font-weight: bold; color: #2A304D;"
            >
            </a-statistic>
          </div>
        </a-col>
        <a-col :span="4"></a-col>
        <a-col :span="4"></a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { enterpriseNameList } from '@/api/system/user'
import { dataStatistics } from '@/api/business/statistics'

export default {
  name: 'Index',
  components: {},
  computed: {
    ...mapGetters(['avatar', 'nickname', 'isEnterpriseOrStaff', 'enterpriseId', 'deptId'])
  },
  data() {
    return {
      enterpriseList: [],
      number1: 10570,
      number2: 10570,
      number3: 10570,
      number4: 10570,
      number5: 10570,
      number6: 10570,
      number7: 10570,
      number8: 10570,
      queryParam: {
        enterpriseId: undefined,
        deviceName: undefined
      }
    }
  },
  created() {
    this.getData()
    enterpriseNameList().then(res => {
      this.enterpriseList = res.data
    })
  },
  mounted() {
  },
  methods: {
    getData() {
      dataStatistics(this.queryParam).then(res => {
        console.log('res : ', res)
        this.number1 = res.data.number1
        this.number2 = res.data.number2
        this.number3 = res.data.number3
        this.number4 = res.data.number4
        this.number5 = res.data.number5
        this.number6 =  Math.round(res.data.number6* 100) / 100
        this.number7 =  Math.round(res.data.number7 * 100) / 100
        this.number8 = Math.round(res.data.number8 * 100) / 100
      })
    },
    getTimeState() {
      // 获取当前时间
      const timeNow = new Date()
      // 获取当前小时
      const hours = timeNow.getHours()
      // 设置默认文字
      let state = ``
      // 判断当前时间段
      if (hours >= 0 && hours <= 10) {
        state = `早上好`
      } else if (hours > 10 && hours <= 14) {
        state = `中午好`
      } else if (hours > 14 && hours <= 18) {
        state = `下午好`
      } else if (hours > 18 && hours <= 24) {
        state = `晚上好`
      }
      return state
    },
    handleQuery() {
      this.getData();
    },
    resetQuery() {
      this.queryParam = {
        enterpriseId: undefined,
        deviceName: undefined,
      }
      this.handleQuery();
    }
  }
}
</script>

<style lang="less" scoped>
@import "./index.less";

:global(.ant-statistic-title) {
  color: #2A304D;
  font-size: 12px;
  font-weight: 400;
}

:global(.ant-statistic-content-value-int) {
  font-size: 30px;
}

:global(.ant-card) {
  background: unset;
}

.statistic-card {
  //width: 312px;
  height: 120px;
  border-radius: 4px;
  background: #EFF3FC;
  padding: 18px 32px;
}

.bg{
  background-image: url(http://www.chengxinzhijian.com/listbg3.png);
  background-size: cover; /* 使背景图像覆盖整个容器 */
  background-position: center; /* 背景图像居中 */
}

</style>
